/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
	const [navbarOpen, setNavbarOpen] = React.useState(false);
	return (
		<>
			<nav className='top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg'>
				<div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
					<div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
						<Link
							className='text-white flex font-bold leading-relaxed py-2 whitespace-nowrap uppercase'
							to='/'>
							<img src='/logo.png' className='w-12' />
							<span className='mx-2 mt-1 text-2xl'>شركة الدثور للصرافة</span>
						</Link>
						<button
							className='cursor-pointer text-xl leading-none px-5 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
							type='button'
							onClick={() => setNavbarOpen(!navbarOpen)}>
							<i className='text-white fas fa-bars'></i>
						</button>
					</div>
					<div
						className={
							'lg:flex mt-3 flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none' +
							(navbarOpen ? 'block rounded shadow-lg' : ' hidden')
						}
						id='example-navbar-warning'>
						<ul className='flex flex-col lg:flex-row list-none mr-auto'>
							<li className='flex items-center'>
								<a
									className='lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold'
									href='/#main'>
									الرئيسية
								</a>
							</li>
							<li className='flex items-center'>
								<a
									className='lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold'
									href='/#about'>
									عن الشركة
								</a>
							</li>
							<li className='flex items-center'>
								<a
									className='lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold'
									href='/#services'>
									الخدمات
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
