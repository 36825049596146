import React from 'react';
import Navbar from 'components/Navbars/AuthNavbar.js';
import Footer from 'components/Footers/Footer.js';
import Whatsapp from 'components/whatsapp';

export default function Landing() {
	return (
		<>
			<Navbar transparent />
			<Whatsapp/>
			<main>
				<div
					id='main'
					className='relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75'>
					<div
						className='absolute top-0 w-full h-full bg-center bg-cover'
						style={{
							backgroundImage:
								"url('https://images.unsplash.com/photo-1614267118647-20c5ffa6a6e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2369&q=80')",
						}}>
						<span
							id='blackOverlay'
							className='w-full h-full absolute opacity-50 bg-black'></span>
					</div>
					<div className='container relative mx-auto'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full lg:w-6/12 px-4 ml-auto mr-auto text-center'>
								<h1 className='text-white font-semibold text-4xl'>
									شركة الدثور للصرافة
								</h1>
								<p className='mt-5 text-xl text-blueGray-200'>
									خدماتنا يميزها الأمان و السرعه من خلال مواكبه احدث التطورات
									الإلكترونية في التعاملات المالية.
								</p>
							</div>
						</div>
					</div>
					<div
						className='top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px'
						style={{ transform: 'translateZ(0)' }}>
						<svg
							className='absolute bottom-0 overflow-hidden'
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
							version='1.1'
							viewBox='0 0 2560 100'
							x='0'
							y='0'>
							<polygon
								className='text-blueGray-200 fill-current'
								points='2560 0 2560 100 0 100'></polygon>
						</svg>
					</div>
				</div>

				<section className='pb-20 bg-blueGray-200 -mt-24'>
					<div className='container mx-auto px-4'>
						<div className='flex flex-wrap'>
							<div className='pt-6 w-full md:w-4/12 px-4 text-center'>
								<div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
									<div className='px-4 py-10 flex-auto'>
										<div className='text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400'>
											<i className='fas fa-award'></i>
										</div>
										<h6 className='text-xl font-semibold'>
											خدمات مالية متنوعة
										</h6>
									</div>
								</div>
							</div>

							<div className='w-full md:w-4/12 px-4 text-center'>
								<div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
									<div className='px-4 py-10 flex-auto'>
										<div className='text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400'>
											<i className='fas fa-retweet'></i>
										</div>
										<h6 className='text-xl font-semibold'>
											افضل خبرة في السوق العراقي
										</h6>
									</div>
								</div>
							</div>

							<div className='pt-6 w-full md:w-4/12 px-4 text-center'>
								<div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
									<div className='px-4 py-10 flex-auto'>
										<div className='text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400'>
											<i className='fas fa-fingerprint'></i>
										</div>
										<h6 className='text-xl font-semibold'>
											اكثر من 10 سنوات خبرة
										</h6>
									</div>
								</div>
							</div>
						</div>

						<div
							id='about'
							className='container mx-auto bg-white px-3 py-10 rounded-lg mt-20'>
							<div className='items-center flex flex-wrap'>
								<div className='w-full md:w-5/12 ml-auto mr-auto px-4'>
									<div className='md:pr-12'>
										<div className='p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full'>
											<i className='fas fa-rocket text-xl'></i>
										</div>
										<h3 className='text-3xl font-semibold'>عن الشركة</h3>
										<p className='mt-4 text-lg leading-relaxed text-blueGray-500'>
											تهدف الشركة منذ تأسيسها إلى تقديم خدمات ماليه لعملائها،
											وأكثر ما يميزها الأمان و السرعة من خلال مواكبه احدث
											التطورات الإلكترونية في التعاملات المالية و التي تعبر عن
											متطلبات العميل و الذي يمثل أهم أهداف الشركة على الإطلاق
											حيث يصب تركيز الشركة الدائم على الاطلاع على احتياجات سوق
											العمل للأشخاص و الشركات و العمل على تلبيتها.
										</p>
										<p className='mt-4 text-lg leading-relaxed text-blueGray-500'>
											ان نكون شركة صرافة رائدة في تقديم الخدمات المالية :
											الصرافة و الحوالات و خدمات الدفع الإلكتروني.
										</p>
										<p className='my-4 text-lg leading-relaxed text-blueGray-500'>
											تقديم خدمات ماليه لعملائها و أكثر ما يميزها الأمان و
											السرعه من خلال مواكبه احدث التطورات الإلكترونية في
											التعاملات المالية.
										</p>
									</div>
								</div>
								<div className='w-full md:w-4/12 ml-auto mr-auto px-4'>
									<img
										alt='...'
										className='max-w-full rounded-lg shadow-lg'
										src='https://images.unsplash.com/photo-1565347878134-064b9185ced8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80'
									/>
								</div>
							</div>
						</div>

						<div id='services' className='flex flex-wrap items-center mt-32'>
							<div className='w-full md:w-5/12 px-4 mr-auto ml-auto'>
								<div className='text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white'>
									<i className='fas fa-user-friends text-xl'></i>
								</div>
								<h3 className='text-3xl mb-2 font-semibold leading-normal'>
									حوالات ويسترين يونيون
								</h3>
								<p className='text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600'>
									حوالات ويسترن يونيون هي خدمة تحويل الأموال الدولية الشهيرة
									التي تسمح للأشخاص بإرسال واستلام الأموال في جميع أنحاء العالم
									بسهولة وأمان. ويتميز هذا النوع من الحوالات بكونه سريعاً
									وموثوقاً ومتاحاً في العديد من المناطق حول العالم.
								</p>
								<p className='text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600'>
									تعمل خدمة ويسترن يونيون عن طريق تحويل الأموال من خلال وكالاتها
									المنتشرة في جميع أنحاء العالم. وتوفر الخدمة خيارات متعددة
									للدفع، بما في ذلك الدفع النقدي والتحويل البنكي والدفع
									الإلكتروني.
								</p>
							</div>

							<div className='w-full md:w-4/12 px-4 mr-auto ml-auto'>
								<div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-800'>
									<img
										alt='...'
										src='https://content.pymnts.com/wp-content/uploads/2021/02/Western-Union-Barclays-Mastercard-B2B.jpg'
										className='w-full align-middle rounded-t-lg'
									/>
									<blockquote className='relative p-8 mb-4'>
										<h4 className='text-xl font-bold text-white'>
											حوالات ويسترين يونيون
										</h4>
										<p className='text-md font-light mt-2 text-white'>
											يتم استخدام حوالات ويسترن يونيون بشكل شائع من قبل الأشخاص
											الذين يريدون إرسال الأموال بسرعة وأمان لأصدقائهم وعائلاتهم
											في الخارج، وأيضاً من قبل الأشخاص الذين يحتاجون إلى إرسال
											الأموال للأعمال التجارية والشركات في الخارج.
										</p>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className='relative py-20'>
					<div
						className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20'
						style={{ transform: 'translateZ(0)' }}>
						<svg
							className='absolute bottom-0 overflow-hidden'
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
							version='1.1'
							viewBox='0 0 2560 100'
							x='0'
							y='0'>
							<polygon
								className='text-white fill-current'
								points='2560 0 2560 100 0 100'></polygon>
						</svg>
					</div>

					<div className='container mx-auto px-4'>
						<div className='flex flex-wrap justify-center text-center mb-24'>
							<div className='w-full lg:w-6/12 px-4'>
								<h2 className='text-3xl font-semibold'>خدمات مالية متنوعة</h2>
							</div>
						</div>
					</div>

					<div className='container mx-auto px-4'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-3'>
								<div className='md:pr-12'>
									<h3 className='text-3xl font-semibold'>بيع وشراء الدولار</h3>
									<p className='mt-4 text-lg leading-relaxed text-blueGray-500'>
										خدمة صرف العملات العالمية بأسعار تنافسية من خلال فروعنا
										المنتشرة في كافة أنحاء العراق
									</p>
								</div>
							</div>
							<div className='w-full md:w-4/12 ml-auto mr-auto px-4'>
								<img
									alt='...'
									className='max-w-full rounded-lg shadow-lg'
									src='https://images.unsplash.com/photo-1593672755342-741a7f868732?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80'
								/>
							</div>
						</div>
					</div>

					<div className='container mx-auto px-4 pt-20'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-3'>
								<div className='md:pr-12'>
									<h3 className='text-3xl font-semibold'>حوالات خارجية</h3>
									<p className='mt-4 text-lg leading-relaxed text-blueGray-500'>
										خدمة تحويل عالمية لأرسال واستقبال الأموال بالتعاون مع شركة
										ويسترن يونين والمنتشرة في اكثر من 200 دولة حول العالم
									</p>
								</div>
							</div>
							<div className='w-full md:w-4/12 ml-auto mr-auto px-4'>
								<img
									alt='...'
									className='max-w-full rounded-lg shadow-lg'
									src='https://content.pymnts.com/wp-content/uploads/2021/02/Western-Union-Barclays-Mastercard-B2B.jpg'
								/>
							</div>
						</div>
					</div>
					<div className='container mx-auto px-4 pt-20'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-3'>
								<div className='md:pr-12'>
									<h3 className='text-3xl font-semibold'>صرف الرواتب</h3>
									<p className='mt-4 text-lg leading-relaxed text-blueGray-500'>
										خدمة مالية لصرف رواتب الموظفين والمتقاعدين من القطاع العام و
										الخاص الموطنين على بطاقة كي كارد
									</p>
								</div>
							</div>
							<div className='w-full md:w-4/12 ml-auto mr-auto px-4'>
								<img
									alt='...'
									className='max-w-full rounded-lg shadow-lg'
									src='https://www.idb.iq/storage/pos-new-1.jpg'
								/>
							</div>
						</div>
					</div>
				</section>

				{/* <section className='pb-20 relative block bg-blueGray-800'>
					<div
						className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20'
						style={{ transform: 'translateZ(0)' }}>
						<svg
							className='absolute bottom-0 overflow-hidden'
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
							version='1.1'
							viewBox='0 0 2560 100'
							x='0'
							y='0'>
							<polygon
								className='text-blueGray-800 fill-current'
								points='2560 0 2560 100 0 100'></polygon>
						</svg>
					</div>

					<div className='container mx-auto px-4 pt-20 lg:pt-24 lg:pb-64'>
						<div className='flex flex-wrap text-center justify-center'>
							<div className='w-full lg:w-6/12 px-4'>
								<h2 className='text-4xl font-semibold text-white'>
									Build something
								</h2>
								<p className='text-lg leading-relaxed mt-4 mb-4 text-blueGray-400'>
									Put the potentially record low maximum sea ice extent tihs
									year down to low ice. According to the National Oceanic and
									Atmospheric Administration, Ted, Scambos.
								</p>
							</div>
						</div>
						<div className='flex flex-wrap mt-12 justify-center'>
							<div className='w-full lg:w-3/12 px-4 text-center'>
								<div className='text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
									<i className='fas fa-medal text-xl'></i>
								</div>
								<h6 className='text-xl mt-5 font-semibold text-white'>
									Excelent Services
								</h6>
								<p className='mt-2 mb-4 text-blueGray-400'>
									Some quick example text to build on the card title and make up
									the bulk of the card's content.
								</p>
							</div>
							<div className='w-full lg:w-3/12 px-4 text-center'>
								<div className='text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
									<i className='fas fa-poll text-xl'></i>
								</div>
								<h5 className='text-xl mt-5 font-semibold text-white'>
									Grow your market
								</h5>
								<p className='mt-2 mb-4 text-blueGray-400'>
									Some quick example text to build on the card title and make up
									the bulk of the card's content.
								</p>
							</div>
							<div className='w-full lg:w-3/12 px-4 text-center'>
								<div className='text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
									<i className='fas fa-lightbulb text-xl'></i>
								</div>
								<h5 className='text-xl mt-5 font-semibold text-white'>
									Launch time
								</h5>
								<p className='mt-2 mb-4 text-blueGray-400'>
									Some quick example text to build on the card title and make up
									the bulk of the card's content.
								</p>
							</div>
						</div>
					</div>
				</section> */}
			</main>
			<Footer />
		</>
	);
}
