import { isMobile } from 'react-device-detect';

const Whatsapp = () => {
  return (
    <div className='fixed bottom-4 right-4 z-10'>
      {isMobile ? (
        <a
          className='block lg:hidden bg-green-600 hover:bg-green-700 focus:ring-green-500 items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2'
          target='_blank'
          rel='noreferrer'
          href={`whatsapp://send?phone=+9647733601313`}
        >
          <div className='flex'>
            <i className='fa-brands fa-2xl fa-whatsapp py-3'></i>
          </div>
        </a>
      ) : (
        <a
          className='hidden lg:block bg-green-600 hover:bg-green-700 focus:ring-green-500 items-center rounded-full p-5 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2'
          target='_blank'
          rel='noreferrer'
          href={`https://wa.me/+9647733601313`}
        >
          <div className='flex'>
            <i className='fa-brands fa-2xl fa-whatsapp py-3'></i>
          </div>
        </a>
      )}
    </div>
  );
};

export default Whatsapp;