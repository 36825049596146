import React from "react";

export default function Footer() {
  return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-center lg:text-right">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">شركة الدثور للصرافة</h4>
            <h5 className="text-lg mb-2 mt-4 text-blueGray-600">
              بغداد - زيونة شارع الربيعي - عمارة سنتر زيونة
            </h5>
            <div>
              <h5 className="text-lg my-2 text-blueGray-600">
                للأستفسار يرجى الأتصال بالرقم
              </h5>
              <h5 dir="ltr" className="text-lg my-2 text-blueGray-600">
                <a href="tel:+9647733601313">
                  <span>+9647733601313</span>
                </a>
              </h5>
            </div>
            <div>
              <h5 className="text-lg my-2 text-blueGray-600">
                البريد الالكتروني
              </h5>
              <h5 dir="ltr" className="text-lg my-2 text-blueGray-600">
                <a href="mailto:adthorco@gmail.com?subject = Feedback&body = Message">
                  <span>adthorco@gmail.com</span>
                </a>
              </h5>
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto my-2">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  روابط مهمة
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      target="_blank"
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="https://www.cabinet.iq/"
                      rel="noreferrer"
                    >
                      الامانة العامة لمجلس الوزراء
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="https://mof.gov.iq/"
                      rel="noreferrer"
                    >
                      وزارة المالية
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="https://cbi.iq/"
                      rel="noreferrer"
                    >
                      البنك المركزي العراقي
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4 my-2">
                <span className="block uppercase text-blueGray-500 text-md font-semibold mb-2">
                  الاقسام
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="/#main"
                    >
                      الرئيسية
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="/#about"
                    >
                      عن الشركة
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-md"
                      href="/#services"
                    >
                      الخدمات
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              جميع الحقوق محفوظة © {new Date().getFullYear()} لشركة الدثور
              للصرافة
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
